import videojs from "video.js";

import type { VideoJSPlayer } from "../types/VideoJSPlayer";
import { configPlaylistWithDRM } from "@/components/video-player-v2/services/configPlaylistWithDRM";
import { constructAdUrl } from "@/components/video-player-v2/helpers/constructAdUrl";
import { isNonNullable } from "@/types/isNonNullable";
import type {
  VideojsPlaylistItem,
  VideojsPlayerConfig,
} from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import { configVideoAnalytics } from "@/components/video-player-v2/services/configVideoAnalytics";

export const initVideoPlayer = (
  placeholderElement: HTMLDivElement,
  config: VideojsPlayerConfig,
  playlist: Array<VideojsPlaylistItem>,
  npawAccountCode: string,
): VideoJSPlayer | null => {
  // is player already initialised check
  if (placeholderElement.hasChildNodes()) {
    return null;
  }
  const currentVideoEl = placeholderElement.appendChild(
    document.createElement("video"),
  );
  currentVideoEl.classList.add("video-js");
  const player: VideoJSPlayer = videojs(currentVideoEl, config, async () => {
    configPlaylistWithDRM(player, playlist);

    // Attach ads
    if (isNonNullable(playlist[0]?.ads)) {
      const adTagUrl = constructAdUrl(
        playlist[0]?.ads[0]?.urls[0] ?? "",
        playlist[0]?.ads[0]?.customParameters,
      );

      if (player.ima) {
        // since IMA is now loaded as a plugin instead of function,
        // we need to manually provide "player" as argument
        // in order for plugin to get access to context
        player.ima(player, {
          adTagUrl,
        });
      }
    }

    await configVideoAnalytics({
      player,
      config: playlist[0]?.youboraConfig ?? {},
      npawAccountCode,
    });
  });

  return player;
};

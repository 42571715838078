import { parseYouboraValues } from "@/components/video-player-v2/helpers/parseYouboraConfigValues";
import type { GoogleAdsManagerResponse } from "@/components/video-player-v2/types/GoogleAdsManagerResponse";
import type { YouboraConfigValue } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";
import { sendImaTrackingEvents } from "@/components/video-player-v2/services/sendAdTrackingEvent";
import type { google } from "@alugha/ima/lib/typings/ima";
import { isNullable } from "@/types/isNullable";

interface ConfigVideoAnalyticsProps {
  player: VideoJSPlayer;
  config: Record<string, YouboraConfigValue>;
  npawAccountCode: string;
}

export const configVideoAnalytics = async ({
  player,
  config,
  npawAccountCode,
}: ConfigVideoAnalyticsProps): Promise<void> => {
  if (typeof window !== "undefined") {
    const NpawPlugin = await import("npaw-plugin");
    const parsed = parseYouboraValues(config);
    const npawPluginInstance = new NpawPlugin.default(npawAccountCode);

    // As per instruction from NPAW support, we need to host JSON config for adapters locally
    npawPluginInstance.registerAdapter(
      player,
      "/next-assets/videojsNPAWAdapter.json",
    );

    npawPluginInstance.setAnalyticsOptions(parsed);

    player.one("ads-manager", ({ adsManager }: GoogleAdsManagerResponse) => {
      npawPluginInstance.registerAdsAdapter(
        adsManager,
        "/next-assets/googleIMAAdapter.json",
      );

      if (isNullable(player.ima)) {
        return;
      }

      //@ts-expect-error: @alugha/ima exports enum only as type, so we need it as value also
      adsManager.addEventListener("start", (event: google.ima.AdEvent) => {
        sendImaTrackingEvents({ player, event });
      });
    });
  }
};

import type { DrmProperties } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import type { VideojsEmeOptions } from "@/components/video-player-v2/types/VideojsEmeOptions";
import { base64EncodeUint8Array } from "@/components/video-player-v2/helpers/base64EncodeUint8Array";
import { isValidHTTPStatusCode } from "@/utils/isValidHTTPStatusCode";
import { base64DecodeUint8Array } from "@/components/video-player-v2/helpers/base64DecodeUint8Array";
import { isNonNullable } from "@/types/isNonNullable";

type GetLicenseFunctionReturnType =
  | ((
      _: VideojsEmeOptions,
      contentId: string,
      keyMessage: Uint8Array,
      callback: (err: Error | null, licence?: Uint8Array) => void,
    ) => void)
  | null;

export function getLicenseFunction(
  drmData: DrmProperties,
): GetLicenseFunctionReturnType {
  if (isNonNullable(drmData.processSpcUrl) && drmData.processSpcUrl) {
    return null;
  }

  return function (
    _: VideojsEmeOptions,
    contentId: string,
    keyMessage: Uint8Array,
    callback: (err: Error | null, licence?: Uint8Array) => void,
  ) {
    const data =
      "spc=" + base64EncodeUint8Array(keyMessage) + "&assetId=" + contentId;

    fetch("https://fairplay.keyos.com/api/v4/getLicense", {
      method: "POST",
      headers: { customdata: drmData.token },
      body: data,
      //responseType: "text",
    })
      .then((response) => {
        if (!isValidHTTPStatusCode(response.status)) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((stringKey) => {
        callback(null, base64DecodeUint8Array(stringKey));
      })
      .catch((err: Error) => {
        callback(err);
      });
  };
}

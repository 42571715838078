import type { YouboraConfigValue } from "@motain/xpa-proto-files-web/lib/types/videojs_player";

export function parseYouboraValues(
  youboraConfig: Record<string, YouboraConfigValue>,
): Record<string, string | boolean | number> {
  const result: Record<string, string | boolean | number> = {};

  Object.entries(youboraConfig).forEach(([key, value]) => {
    switch (value.kind?.$case) {
      case "stringValue":
        result[key] = value.kind.stringValue;
        break;
      case "intValue":
        result[key] = value.kind.intValue;
        break;
      case "boolValue":
        result[key] = value.kind.boolValue;
        break;
      case "doubleValue":
        result[key] = value.kind.doubleValue;
        break;
      default:
        break;
    }
  });

  return result;
}

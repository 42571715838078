import type { DrmProperties } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import type { VideojsEmeOptions } from "@/components/video-player-v2/types/VideojsEmeOptions";
import { arrayToString } from "@/components/video-player-v2/helpers/arrayToString";
import { isNonNullable } from "@/types/isNonNullable";

type GetContentIdReturnType =
  | ((_: VideojsEmeOptions, initData: Uint8Array) => string)
  | null;

export function getContentIdFunction(
  drmData: DrmProperties,
): GetContentIdReturnType {
  if (isNonNullable(drmData.processSpcUrl) && drmData.processSpcUrl !== "") {
    return null;
  }

  return function (_: VideojsEmeOptions, initData: Uint8Array) {
    const contentId = arrayToString(initData);

    if (contentId.includes("skd://")) {
      const idSubstr = contentId.split("skd://")[1] ?? "";

      return idSubstr.substring(0, 32) || "";
    }
    throw new Error(
      "Invalid Content ID format. The format of the Content ID must be the following :skd://xxx where xxx is the Key ID in hex format.",
    );
  };
}

import { trackingService } from "@/modules/analytics";
import type { TypedServerParameter } from "@motain/xpa-proto-files-web/lib/types/tracking";
import {
  EventType,
  ServerParameterType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import type { google } from "@alugha/ima";
import { isNullable } from "@/types/isNullable";
import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";

interface ImaTrackingEventProps {
  player: VideoJSPlayer;
  event: google.ima.AdEvent;
}

const sendImaTrackingEvents = ({
  player,
  event,
}: ImaTrackingEventProps): void => {
  const currentPlaylistItem =
    player.playlist?.()[player.playlist.currentItem()];

  const trackingEvents = currentPlaylistItem?.ads[0]?.adTrackingEvents;

  if (isNullable(trackingEvents) || trackingEvents.length == 0) {
    return;
  }

  const currentAd = event.getAd();

  if (currentAd) {
    const adPodInfo = currentAd.getAdPodInfo();
    const adDuration = currentAd.getDuration().toString();

    let adType: string;
    const adTypeNumber = adPodInfo.getPodIndex();
    switch (adTypeNumber) {
      case 0:
        adType = "preroll";
        break;
      case -1:
        adType = "postroll";
        break;
      default:
        adType = "midroll";
        break;
    }

    const podPosition = adPodInfo.getAdPosition().toString();

    const adUnitId = currentAd.getAdId();

    trackingEvents.forEach((trackingEvent) => {
      const clientAdParametersTyped: Record<string, TypedServerParameter> = {
        ad_duration: {
          type: ServerParameterType.INTEGER,
          value: adDuration,
        },
        ad_type: {
          type: ServerParameterType.STRING,
          value: adType,
        },
        ad_unit_id: {
          type: ServerParameterType.STRING,
          value: adUnitId,
        },
        pod_position: {
          type: ServerParameterType.STRING,
          value: podPosition,
        },
        network_name: {
          type: ServerParameterType.STRING,
          value: "Google",
        },
      };
      const adTrackingEvent = {
        ...trackingEvent,
        typedServerParameter: {
          ...clientAdParametersTyped,
          ...trackingEvent.typedServerParameter,
        },
      };

      trackingService.sendXpaTracking(
        [adTrackingEvent],
        EventType.VIDEO_AD_STARTED,
      );
    });
  }
};

export { sendImaTrackingEvents };

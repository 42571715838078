import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import videojs from "video.js";

// Load IMA as plugin module instead of a script
import ima from "videojs-ima";

import "videojs-playlist";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import "videojs-contrib-eme";

import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";
import type { VideoJSPlayerProps } from "@/components/video-player-v2/types/VideoJSPlayerProps";
import {
  checkForAdBlocker,
  importAdPlugin,
} from "@/components/video-player-v2/services/adsPluginService";
import { initVideoPlayer } from "@/components/video-player-v2/services/initVideoPlayer";
import { PlayerLoader } from "@/components/video-player-v2/elements/PlayerLoader";
import { AdBlockerBanner } from "@/components/video-player-v2/elements/AdBlockerBanner";
import { isNonNullable } from "@/types/isNonNullable";
import type { VideojsPlaylistItem } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import { attachPlayerEvents } from "@/components/video-player-v2/helpers/attachPlayerEvents";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { useFullScreen } from "@/utils/hooks/useFullScreen";
import { VideoPlayerEvent } from "@/components/video-player-v2/types/VideoPlayerEventType";
import { sendVideoPlayedEvent } from "@/components/video-player-v2/services/sendVideoPlayerEvent";
import useTranslation from "next-translate/useTranslation";
import Script from "next/script";
import { isBrowserSupportedForVideoPlayback } from "@/components/video-player-v2/constants/isBrowserSupportedForVideoPlayback";
import { initComscoreAnalytics } from "@/components/video-player-v2/services/initComscoreAnalytics";
import { remoteLogger } from "@/utils/remoteLogger";

const VideoPlayer: FC<VideoJSPlayerProps> = ({
  config,
  playlist,
  shouldShowAdBlocker,
  onAdBlockerActive,
  CustomLoader,
  adBlockerBaitScriptUrl,
  events,
  shouldReInit = true,
}) => {
  const [loading, setLoading] = useState(false);

  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const playerInstanceRef = useRef<VideoJSPlayer | null>(null);
  const playlistRef = useRef<Array<VideojsPlaylistItem> | null>(null);
  const fullScreenRef = useFullScreen();
  const { t } = useTranslation("web-payments");

  useEffect(() => {
    // prevent fetching bait script if ad-blocker already active
    if (shouldShowAdBlocker || !isBrowserSupportedForVideoPlayback) {
      return;
    }
    setLoading(true);

    checkForAdBlocker(adBlockerBaitScriptUrl)
      .catch((e: Error) => {
        onAdBlockerActive(e);
        throw new Error("AD BLOCKER ACTIVE");
      })
      .then(importAdPlugin)
      .then((plugin) => {
        // check if plugin already registered
        const adsPlugin = videojs.getPlugin("ads");
        if (typeof adsPlugin !== "function") {
          videojs.registerPlugin("ads", plugin);
        }

        if (!isNonNullable(videojs.getPlugin("ima"))) {
          videojs.registerPlugin("ima", ima);
        }

        if (placeholderRef.current && !playerInstanceRef.current) {
          // Copy playlist as object could be coming from immutable
          // playlist plugin is trying to extend it
          playlistRef.current = structuredClone(playlist);

          playerInstanceRef.current = initVideoPlayer(
            placeholderRef.current,
            config,
            playlistRef.current,
            envBasedConfig.youboraAccountCode,
          );

          if (playerInstanceRef.current !== null) {
            if (
              isNonNullable(playlist[0]) &&
              isNonNullable(playlist[0]?.comscoreMetadata)
            ) {
              initComscoreAnalytics({
                player: playerInstanceRef.current,
                comscoreMetadata: playlist[0].comscoreMetadata,
              });
            }

            // Additional custom events
            attachPlayerEvents(playerInstanceRef.current, events);
          }

          playerInstanceRef.current?.on(VideoPlayerEvent.Ended, () => {
            sendVideoPlayedEvent({
              player: playerInstanceRef.current,
              options: { config, playlist },
              duration: playerInstanceRef.current?.currentTime() ?? 0,
              fullScreenRef,
              wasFinished: true,
            });
          });
        }
      })
      .catch((e) => {
        // INIT ERROR
        remoteLogger.error("Video player initialisation error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      if (
        !document.pictureInPictureElement &&
        isNonNullable(playerInstanceRef.current) &&
        shouldReInit
      ) {
        if (
          isNonNullable(playerInstanceRef.current.currentTime()) &&
          playerInstanceRef.current.currentTime() !== 0
        ) {
          sendVideoPlayedEvent({
            player: playerInstanceRef.current,
            options: { config, playlist },
            duration: playerInstanceRef.current.currentTime() ?? 0,
            fullScreenRef,
            wasFinished: false,
          });
        }

        playerInstanceRef.current.dispose();
        playlistRef.current = null;
        playerInstanceRef.current = null;
      }
    };
  }, [
    config,
    shouldReInit,
    shouldShowAdBlocker,
    playlist,
    onAdBlockerActive,
    adBlockerBaitScriptUrl,
    events,
    fullScreenRef,
  ]);

  return (
    <>
      {loading && (CustomLoader ?? <PlayerLoader />)}
      {(shouldShowAdBlocker || !isBrowserSupportedForVideoPlayback) && (
        <AdBlockerBanner
          text={
            isBrowserSupportedForVideoPlayback
              ? t`ADBLOCKER_BANNER_HEADLINE`
              : t`ADBLOCKER_BANNER_BROWSER_NOT_SUPPORTED`
          }
          posterUrl={playlist[0]?.poster}
        />
      )}
      <div video-vjs-player="true" ref={placeholderRef} />
      <Script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js" />
      <Script src="https://onefootball-website-assets.s3.eu-west-1.amazonaws.com/scripts/comscore.js" />
    </>
  );
};

export { VideoPlayer };

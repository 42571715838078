import { create, func, type } from "superstruct";
import { configComscoreAnalytics } from "@/components/video-player-v2/helpers/configComscoreAnalytics";
import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";
import type { ComscoreMetaData } from "@motain/xpa-proto-files-web/lib/types/videojs_player";

interface InitComscoreAnalyticsProps {
  player: VideoJSPlayer;
  comscoreMetadata: ComscoreMetaData;
}

export const initComscoreAnalytics = ({
  player,
  comscoreMetadata,
}: InitComscoreAnalyticsProps): void => {
  const StramingAnalyticsStruct = type({
    notifyPlay: func(),
    notifyPause: func(),
    notifyEnd: func(),
  });

  const streamingAnalytics = create(
    configComscoreAnalytics(comscoreMetadata),
    StramingAnalyticsStruct,
    "can not create",
  );

  player.on("play", () => {
    streamingAnalytics.notifyPlay();
  });

  player.on("pause", () => {
    streamingAnalytics.notifyPause();
  });

  player.on("ended", () => {
    streamingAnalytics.notifyEnd();
  });

  player.on("dispose", () => {
    streamingAnalytics.notifyEnd();
  });
};

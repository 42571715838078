// Before loading ads plugin, we need to check if the ad blocker is active.
// We try to load bait script, which will fail to load if adblocker is present. If that is the case, we throw an error.
// If we try to load the plugin and the ad blocker is active, the plugin will throw a runtime error.
export const checkForAdBlocker = async (
  baitScriptUrl: string,
): Promise<void> => {
  try {
    await fetch(baitScriptUrl, {
      method: "HEAD",
      cache: "no-store",
      mode: "no-cors",
    });
  } catch (error) {
    throw new Error("AD BLOCKER ACTIVE");
  }
};

export const importAdPlugin = async (): Promise<() => void> => {
  const plugin = await import("videojs-contrib-ads");
  return plugin.default;
};

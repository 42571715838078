import type { CustomAdsParameters } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import { getRecordEntries } from "@/utils/getRecordEntries";
import { camelToSnakeCase } from "@/components/video-player-v2/helpers/camelToSnakeCase";
import { isNonNullable } from "@/types/isNonNullable";

export const constructAdUrl = (
  backendUrl: string,
  customParams: CustomAdsParameters | undefined,
): string => {
  try {
    const url = new URL(backendUrl);

    //combine customParams from url and explicit ones
    if (isNonNullable(customParams)) {
      const urlCustomParamsString = decodeURIComponent(
        url.searchParams.get("cust_params") ?? "",
      );

      const allCustomParams = new URLSearchParams(urlCustomParamsString);

      const custParamRecord: Record<
        | "sku"
        | "rightsId"
        | "providerId"
        | "contentLength"
        | "videoLengthBucket"
        | "contentId"
        | "contentTags",
        string
      > = customParams;

      getRecordEntries(custParamRecord).forEach(([key, value]) => {
        allCustomParams.set(camelToSnakeCase(key), value);
      });

      url.searchParams.set("cust_params", allCustomParams.toString());
    }
    return url.toString();
  } catch (e: unknown) {
    return "";
  }
};

import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";
import type { VideojsEmeOptions } from "@/components/video-player-v2/types/VideojsEmeOptions";
import type { VideojsPlaylistItem } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import { getContentIdFunction } from "@/components/video-player-v2/helpers/getContentIdFunction";
import { getLicenseFunction } from "@/components/video-player-v2/helpers/getLicenseFunction";
import videojs from "video.js";
import { isNonNullable } from "@/types/isNonNullable";
import { isNullable } from "@/types/isNullable";

export function configPlaylistWithDRM(
  player: VideoJSPlayer,
  playlist: Array<VideojsPlaylistItem>,
): void {
  const shouldCreateSingleSource =
    videojs.browser.IS_SAFARI &&
    (videojs.browser.IS_IPHONE || videojs.browser.IS_IPAD);

  playlist.forEach((item) => {
    if (item.drm) {
      const drmToken = item.drm.token;
      if (player.eme !== undefined && isNonNullable(drmToken)) {
        player.eme({
          emeHeaders: {
            customdata: drmToken,
          },
        });
      }

      item.sources.forEach((source, i) => {
        if (isNonNullable(source)) {
          if (
            isNullable(item.drm?.fpCertificateUrl) ||
            item.drm?.fpCertificateUrl === ""
          ) {
            const wvprDashSrc: VideojsEmeOptions = {
              src: source.src,
              type: source.type,
              keySystems: {
                "com.widevine.alpha":
                  item.drm?.widevineUrl === ""
                    ? "https://widevine.keyos.com/api/v4/getLicense"
                    : item.drm?.widevineUrl,
              },
            };
            item.sources[i] = wvprDashSrc;
          } else {
            if (item.drm) {
              const fairplayHlsSrc = {
                src: source.src,
                type: source.type,
                keySystems: {
                  "com.apple.fps.1_0": {
                    certificateUri: item.drm.fpCertificateUrl,
                    licenseUri: item.drm.processSpcUrl,
                    getContentId: getContentIdFunction(item.drm),
                    getLicense: getLicenseFunction(item.drm),
                  },
                },
              };
              item.sources[i] = fairplayHlsSrc;
            }
          }
        }
      });
    }
  });

  // Mobile Safari does not support multiple media sources, so we can not have playlist
  // Also it does support autoplay without user interaction, se we play the ad first
  // and then switch source because ad is detected as user interaction
  if (player.playlist && !shouldCreateSingleSource) {
    player.playlist(playlist);
    player.playlist.autoadvance(0);
  } else {
    // Hack to play video on mobile clients without user interaction
    if (isNonNullable(playlist[0])) {
      player.src(playlist[0].sources[0]);
    }

    player.on("adend", () => {
      if (isNonNullable(playlist[0])) {
        player.src(playlist[0].sources[0]);
        player.autoplay(true);
      }
    });
  }
}

import { isNullable } from "@/types/isNullable";
import type {
  TrackingEvent as XpaTrackingEvent,
  TypedServerParameter,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import {
  ClientParameterType,
  EventType,
  ServerParameterType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import { trackingService } from "@/modules/analytics";
import type { VideojsPlayerComponent } from "@motain/xpa-proto-files-web/lib/types/videojs_player";
import type { MutableRefObject } from "react";
import type { UseFullscreen } from "@/utils/hooks/useFullScreen";
import type { VideoJSPlayer } from "@/components/video-player-v2/types/VideoJSPlayer";

interface VideoPlayedEventProps {
  player: VideoJSPlayer | null;
  options?: VideojsPlayerComponent;
  duration: number;
  fullScreenRef: MutableRefObject<UseFullscreen>;
  wasFinished: boolean;
}

const sendVideoPlayedEvent = ({
  player,
  options,
  duration,
  fullScreenRef,
  wasFinished,
}: VideoPlayedEventProps): void => {
  if (isNullable(player)) {
    return;
  }
  const itemIndex = player.playlist?.currentItem() ?? -1;
  const playlistItem = options?.playlist[itemIndex];

  if (isNullable(playlistItem) || isNullable(playlistItem.trackingEvents)) {
    return;
  }

  const videoPlayedTrackingEvents = playlistItem.trackingEvents
    .filter((event) => event.type === EventType.VIDEO_PLAYED)
    .map((videoPlayedTrackingEvent) => {
      //RUDDERSTACK TRACKING
      const { typedServerParameter, clientParameterRequests } =
        videoPlayedTrackingEvent;

      const clientParameters = clientParameterRequests.reduce<
        Record<string, TypedServerParameter>
      >((record, parameter) => {
        // converting client side parameters to typed parameters
        switch (parameter.type) {
          case ClientParameterType.PARAMETER_VIDEO_PLAYBACK_DURATION_SECONDS:
            record[parameter.key] = {
              type: ServerParameterType.INTEGER,
              value: duration.toString(),
            };

            break;
          case ClientParameterType.PARAMETER_VIDEO_ENTERED_FULLSCREEN_MODE:
            record[parameter.key] = {
              type: ServerParameterType.BOOLEAN,
              value: fullScreenRef.current.wasFullScreen.toString(),
            };

            break;
          case ClientParameterType.PARAMETER_VIDEO_FINISHED:
            record[parameter.key] = {
              type: ServerParameterType.BOOLEAN,
              value: wasFinished.toString(),
            };

            break;

          default:
            break;
        }

        return record;
      }, {});

      const videoPlayedEvent: XpaTrackingEvent = {
        ...videoPlayedTrackingEvent,
        typedServerParameter: {
          ...clientParameters,
          ...typedServerParameter,
        },
        trackers: videoPlayedTrackingEvent.trackers,
      };
      return videoPlayedEvent;
    });

  if (videoPlayedTrackingEvents.length === 0) {
    return;
  }

  trackingService.sendXpaTracking(
    videoPlayedTrackingEvents,
    EventType.VIDEO_PLAYED,
  );
};

export { sendVideoPlayedEvent };

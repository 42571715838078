import type { FC } from "react";
import styles from "./AdBlockerBanner.module.scss";
import { isNonNullable } from "@/types/isNonNullable";
import Image from "next/image";

interface AdBlockerBannerProps {
  text: string;
  posterUrl?: string;
}

export const AdBlockerBanner: FC<AdBlockerBannerProps> = ({
  text,
  posterUrl,
}) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.bannerText}>{text}</p>
      <div className={styles.posterWrapper}>
        {isNonNullable(posterUrl) && (
          <Image src={posterUrl} alt="video-poster" fill />
        )}
      </div>
    </div>
  );
};
